import { MDCRipple } from '@material/ripple'
import { MDCMenu, Corner } from '@material/menu'
import { MDCSlider } from '@material/slider'
import { MDCTextField } from '@material/textfield';

// Create element
function ce (sel) {
  const dotIdx = sel.indexOf('.')
  const tag = dotIdx !== -1 ? sel.slice(0, dotIdx) : sel
  const elm = document.createElement(tag)
  if (dotIdx !== -1) {
    elm.setAttribute('class', sel.slice(dotIdx + 1).replace(/\./g, ' '))
  }
  return elm
}

// We don't enable the MDCChipSet JS, because it listens for keyboard events and
// calls preventDefault, which breaks keyboard activation of links.
// Instead, just create a ripple for the chips.
for (const el of document.querySelectorAll('.mdc-evolution-chip__action')) {
  new MDCRipple(el)
}

// The MDCList JS does not create the ripple itself.
for (const el of document.querySelectorAll('.mdc-list-item')) {
  new MDCRipple(el)
}

for (const el of document.querySelectorAll('.mdc-icon-button')) {
  const iconButtonRipple = new MDCRipple(el)
  iconButtonRipple.unbounded = true
}

for (const el of document.querySelectorAll('.ripple-surface')) {
  new MDCRipple(el)
}

for (const el of document.querySelectorAll('.mdc-text-field')) {
  new MDCTextField(el)
}

function copyFeed (event) {
  navigator.clipboard.writeText(event.currentTarget.href)
  event.preventDefault()
}
for (const el of document.querySelectorAll('.copy-feed')) {
  el.addEventListener('click', copyFeed)
}

let currentMenuEvt
let currentMenuEl
function createMenu(el) {
  // Without this, the menu element expands the viewport to the bottom and left
  // on mobile before the position calculations. This means it thinks that
  // there is more space than there actually is.
  el.style.right = '0'
  el.style.bottom = '0'
  const menu = new MDCMenu(el)
  let corner = Corner.TOP_END
  if (el.classList.contains('menu-from-bottom-start')) corner = Corner.BOTTOM_START
  menu.setAnchorCorner(corner)

  const oldBodyClick = menu.menuSurface.foundation.handleBodyClick
  menu.menuSurface.foundation.handleBodyClick = evt => {
    currentMenuEvt = evt
    oldBodyClick.call(menu.menuSurface.foundation, evt)
  }
  return menu
}

for (const anchorEl of document.querySelectorAll('.mdc-menu-surface--anchor')) {
  const buttonEl = anchorEl.querySelector('button')
  const menuEl = anchorEl.querySelector('.mdc-menu')
  if (buttonEl && menuEl) {
    let menu
    const openMenu = event => {
      // If the menu was open, it will be closed by handleBodyClick,
      // don't open it again.
      if (event === currentMenuEvt && anchorEl === currentMenuEl) return
      currentMenuEl = anchorEl
      if (!menu) {
        menu = createMenu(menuEl)
      }
      menu.open = true
    }
    buttonEl.addEventListener('click', openMenu)
  }
}

// Material Icons from https://fonts.google.com/icons?icon.set=Material+Icons, Apache license
const iconHeadphones = '<path d="M12,3c-4.97,0-9,4.03-9,9v7c0,1.1,0.9,2,2,2h4v-8H5v-1c0-3.87,3.13-7,7-7s7,3.13,7,7v1h-4v8h4c1.1,0,2-0.9,2-2v-7 C21,7.03,16.97,3,12,3z M7,15v4H5v-4H7z M19,19h-2v-4h2V19z"/>'
const iconDescription = '<path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/>'
const iconFileDownload = '<path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"/>'
const iconPause = '<path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>'
const iconPlay = '<path d="M8 5v14l11-7z"/>'
const iconReplay10 = '<path d="M11.99 5V1l-5 5 5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6h-2c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8zm-1.1 11h-.85v-3.26l-1.01.31v-.69l1.77-.63h.09V16zm4.28-1.76c0 .32-.03.6-.1.82s-.17.42-.29.57-.28.26-.45.33-.37.1-.59.1-.41-.03-.59-.1-.33-.18-.46-.33-.23-.34-.3-.57-.11-.5-.11-.82v-.74c0-.32.03-.6.1-.82s.17-.42.29-.57.28-.26.45-.33.37-.1.59-.1.41.03.59.1.33.18.46.33.23.34.3.57.11.5.11.82v.74zm-.85-.86c0-.19-.01-.35-.04-.48s-.07-.23-.12-.31-.11-.14-.19-.17-.16-.05-.25-.05-.18.02-.25.05-.14.09-.19.17-.09.18-.12.31-.04.29-.04.48v.97c0 .19.01.35.04.48s.07.24.12.32.11.14.19.17.16.05.25.05.18-.02.25-.05.14-.09.19-.17.09-.19.11-.32.04-.29.04-.48v-.97z"/>'
const iconForward10 = '<path d="M18,13c0,3.31-2.69,6-6,6s-6-2.69-6-6s2.69-6,6-6v4l5-5l-5-5v4c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8s8-3.58,8-8H18z"/><polygon points="10.9,16 10.9,11.73 10.81,11.73 9.04,12.36 9.04,13.05 10.05,12.74 10.05,16"/><path d="M14.32,11.78c-0.18-0.07-0.37-0.1-0.59-0.1s-0.41,0.03-0.59,0.1s-0.33,0.18-0.45,0.33s-0.23,0.34-0.29,0.57 s-0.1,0.5-0.1,0.82v0.74c0,0.32,0.04,0.6,0.11,0.82s0.17,0.42,0.3,0.57s0.28,0.26,0.46,0.33s0.37,0.1,0.59,0.1s0.41-0.03,0.59-0.1 s0.33-0.18,0.45-0.33s0.22-0.34,0.29-0.57s0.1-0.5,0.1-0.82V13.5c0-0.32-0.04-0.6-0.11-0.82s-0.17-0.42-0.3-0.57 S14.49,11.85,14.32,11.78z M14.33,14.35c0,0.19-0.01,0.35-0.04,0.48s-0.06,0.24-0.11,0.32s-0.11,0.14-0.19,0.17 s-0.16,0.05-0.25,0.05s-0.18-0.02-0.25-0.05s-0.14-0.09-0.19-0.17s-0.09-0.19-0.12-0.32s-0.04-0.29-0.04-0.48v-0.97 c0-0.19,0.01-0.35,0.04-0.48s0.06-0.23,0.12-0.31s0.11-0.14,0.19-0.17s0.16-0.05,0.25-0.05s0.18,0.02,0.25,0.05 s0.14,0.09,0.19,0.17s0.09,0.18,0.12,0.31s0.04,0.29,0.04,0.48V14.35z"/>'

const iconYoutube = '<path d="M10 15V9l5.2 3Zm11.58-7.81a2.5 2.5 0 0 0-1.77-1.77C18.25 5 12 5 12 5s-6.25 0-7.81.42a2.5 2.5 0 0 0-1.77 1.77C2 8.75 2 12 2 12s0 3.25.42 4.81c.23.86.9 1.54 1.77 1.77C5.75 19 12 19 12 19s6.25 0 7.81-.42a2.5 2.5 0 0 0 1.77-1.77C22 15.25 22 12 22 12s0-3.25-.42-4.81"/>'

// Unit card dropdown menu

function createItem (text, icon, link, enabled) {
  let itemTag = 'div'
  if (link && enabled) itemTag = 'a'
  const itemEl = ce(itemTag)
  itemEl.className = 'mdc-list-item mdc-list-item--with-one-line mdc-list-item--with-leading-icon'
  itemEl.setAttribute('role', 'menuitem')
  if (itemTag === 'a') itemEl.href = link
  if (!enabled) {
    itemEl.classList.add('mdc-list-item--disabled')
    itemEl.setAttribute('aria-disabled', 'true')
  }

  const rippleEl = ce('span.mdc-list-item__ripple')
  itemEl.appendChild(rippleEl)

  const startEl = ce('span.mdc-list-item__start')
  itemEl.appendChild(startEl)

  const svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svgEl.setAttribute('viewBox', '0 0 24 24')
  svgEl.innerHTML = icon
  startEl.appendChild(svgEl)

  const contentEl = ce('span.mdc-list-item__content')
  itemEl.appendChild(contentEl)

  const primaryTextEl = ce('span.mdc-list-item__primary-text')
  primaryTextEl.textContent = text
  contentEl.appendChild(primaryTextEl)

  return itemEl
}

function openCardMenu (cardEl, anchorEl) {
  const menuEl = ce('div.mdc-menu.mdc-menu-surface')

  const listEl = ce('div.mdc-list')
  listEl.setAttribute('role', 'menu')
  listEl.setAttribute('aria-hidden', 'true')
  listEl.setAttribute('aria-orientation', 'vertical')
  listEl.setAttribute('tabindex', '-1')
  menuEl.appendChild(listEl)

  const documentUrl = cardEl.dataset.documenturl
  const audioUrl = cardEl.dataset.audiourl
  const youtubeId = cardEl.dataset.youtubeid
  const unitTitle = cardEl.dataset.title
  const moduleTitle = cardEl.dataset.module

  let youtubeUrl = ''
  if (youtubeId) {
    youtubeUrl = 'https://www.youtube.com/watch?v=' + youtubeId
  }
  const documentItem = createItem('Vertiefungsblatt', iconDescription, documentUrl, !!documentUrl)
  listEl.appendChild(documentItem)
  if (documentUrl) {
    documentItem.setAttribute('target', '_blank')
  }
  listEl.appendChild(createItem('Auf YouTube ansehen', iconYoutube, youtubeUrl, !!youtubeUrl))
  const listenItem = createItem('Hören', iconHeadphones, '', !!audioUrl)
  listEl.appendChild(listenItem)
  const audioDownloadItem = createItem('Audio herunterladen', iconFileDownload, audioUrl, !!audioUrl)
  if (audioUrl) {
    audioDownloadItem.setAttribute('download', '')
  }
  listEl.appendChild(audioDownloadItem)

  anchorEl.appendChild(menuEl)
  const menu = createMenu(menuEl)
  menu.open = true
  menuEl.addEventListener('MDCMenuSurface:closed', () => {
    menu.destroy()
    anchorEl.removeChild(menuEl)
  })

  menuEl.addEventListener('MDCMenu:selected', evt => {
    if (evt.detail.item === listenItem && audioUrl) {
      playAudio(unitTitle, moduleTitle, audioUrl)
    }
  })
}

for (const cardEl of document.querySelectorAll('.unit-card')) {
  const moreButton = cardEl.querySelector('.unit-card-more')
  if (moreButton) {
    const anchorEl = ce('div.mdc-menu-surface--anchor.self-start')
    moreButton.parentNode.insertBefore(anchorEl, moreButton)
    anchorEl.appendChild(moreButton)
    moreButton.addEventListener('click', event => {
      if (event === currentMenuEvt && anchorEl === currentMenuEl) return
      currentMenuEl = anchorEl
      openCardMenu(cardEl, anchorEl)
    })
  }
}

for (const buttonEl of document.querySelectorAll('.play-audio-button')) {
  buttonEl.addEventListener('click', () => {
    const audioUrl = buttonEl.dataset.audiourl
    const unitTitle = buttonEl.dataset.title
    const moduleTitle = buttonEl.dataset.module
    playAudio(unitTitle, moduleTitle, audioUrl)
  })
}

// Audio player

let audioPlayer

function formatTime (time) {
  time = Math.floor(time)
  const minutes = Math.floor(time / 60)
  if (time < 3600) return `${minutes}:${(time % 60).toString().padStart(2, '0')}`
  if (time < 360000) return `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}:${(time % 60).toString().padStart(2, '0')}`
  return '–:–'
}

function createIconButton (iconData) {
  const button = ce('button.mdc-icon-button')
  button.appendChild(ce('div.mdc-icon-button__ripple'))
  button.appendChild(ce('span.mdc-icon-button__focus-ring'))
  const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  iconSvg.setAttribute('viewBox', '0 0 24 24')
  if (iconData) iconSvg.innerHTML = iconData
  button.appendChild(iconSvg)

  const iconButtonRipple = new MDCRipple(button)
  iconButtonRipple.unbounded = true
  return button
}

function createAudioPlayer () {
  const audioEl = document.createElement('audio')

  const playerEl = ce('div.sticky.bottom-0.w-full.pt-4.pb-2.bg-white.elevation-8')

  const moduleEl = ce('div.px-4.text-sm')
  playerEl.appendChild(moduleEl)

  const titleEl = ce('div.px-4')
  playerEl.appendChild(titleEl)

  const sliderEl = ce('div.mdc-slider')
  playerEl.appendChild(sliderEl)
  const sliderInputEl = ce('input.mdc-slider__input')
  sliderInputEl.setAttribute('type', 'range')
  sliderInputEl.setAttribute('min', 0)
  sliderInputEl.setAttribute('max', 1)
  sliderInputEl.setAttribute('step', 0.1)
  sliderInputEl.setAttribute('value', 0)
  sliderInputEl.setAttribute('aria-label', 'Zeit-Schieberegler')
  sliderEl.appendChild(sliderInputEl)
  const sliderTrackEl = ce('div.mdc-slider__track')
  sliderEl.appendChild(sliderTrackEl)
  sliderTrackEl.appendChild(ce('div.mdc-slider__track--inactive'))
  const sliderTrackActiveEl = ce('div.mdc-slider__track--active')
  sliderTrackEl.appendChild(sliderTrackActiveEl)
  sliderTrackActiveEl.appendChild(ce('div.mdc-slider__track--active_fill'))
  const sliderThumbEl = ce('div.mdc-slider__thumb')
  sliderEl.appendChild(sliderThumbEl)
  sliderThumbEl.appendChild(ce('div.mdc-slider__thumb-knob'))

  const controlsEl = ce('div.px-4.flex.items-center.space-x-2')
  playerEl.appendChild(controlsEl)

  const timeEl = ce('span.flex-grow.basis-0.tabular-nums')
  controlsEl.appendChild(timeEl)

  const replayButton = createIconButton(iconReplay10)
  controlsEl.appendChild(replayButton)

  const playButton = createIconButton()
  const playButtonSvg = playButton.querySelector('svg')
  controlsEl.appendChild(playButton)

  const forwardButton = createIconButton(iconForward10)
  controlsEl.appendChild(forwardButton)

  const durationEl = ce('span.flex-grow.basis-0.text-right.tabular-nums')
  controlsEl.appendChild(durationEl)

  document.body.appendChild(playerEl)

  const slider = new MDCSlider(sliderEl)

  let lastTime = -1
  let hasUpdateTimeout = false
  let updateTimeoutSum = 0
  let sliderDragging = false
  let dragWasPlaying = false
  let playerVisible = true

  function updatePaused () {
    const paused = audioEl.paused
    playButton.title = paused ? 'Wiedergabe' : 'Pause'
    playButtonSvg.innerHTML = paused ? iconPlay : iconPause
    if ('mediaSession' in navigator) {
      navigator.mediaSession.playbackState = paused ? 'paused' : 'playing';
    }
  }

  function updateDuration () {
    const duration = audioEl.duration
    durationEl.textContent = formatTime(duration)
    if (Number.isFinite(duration)) {
      sliderInputEl.setAttribute('max', duration)
      slider.foundation.setMax(duration)
    }
  }

  function onUpdateTimeTimeout () {
    hasUpdateTimeout = false
    if (sliderDragging) return
    updateTimeText(audioEl.currentTime)
  }

  function updateTimeText (time) {
    const floorTime = Math.floor(time)
    if (floorTime !== lastTime) {
      timeEl.textContent = formatTime(time)
      lastTime = floorTime
    }

    if (sliderDragging) return

    // The timeupdate event fires at a relatively low frequency, so directly
    // using it to update the text results in irregular times between updates.
    // To fix this, we set a timeout for when the seconds increase.
    const secondRolloverIn = (1 - (audioEl.currentTime % 1)) / audioEl.playbackRate
    if (!hasUpdateTimeout && updateTimeoutSum < 1 && secondRolloverIn < 1) {
      if (secondRolloverIn < 0.02) {
        requestAnimationFrame(onUpdateTimeTimeout)
      } else {
        setTimeout(onUpdateTimeTimeout, secondRolloverIn * 1000 - 16)
      }
      updateTimeoutSum += secondRolloverIn
      hasUpdateTimeout = true
    }
  }

  function updateTime () {
    if (sliderDragging) return
    const time = audioEl.currentTime
    updateTimeoutSum = 0
    updateTimeText(time)
    slider.setValue(time)
  }

  function closePlayer () {
    audioEl.pause()
    audioEl.src = ''
    playerEl.classList.add('hidden')
    navigator.mediaSession.playbackState = 'none'
    playerVisible = false
  }

  audioEl.addEventListener('play', updatePaused)
  audioEl.addEventListener('pause', updatePaused)
  audioEl.addEventListener('durationchange', updateDuration)
  audioEl.addEventListener('timeupdate', updateTime)

  function doPlayPause () {
    if (audioEl.paused) audioEl.play()
    else audioEl.pause()
  }
  function doReplay () {
    audioEl.currentTime -= 10
  }
  function doForward () {
    audioEl.currentTime += 10
  }
  playButton.addEventListener('click', doPlayPause)
  replayButton.addEventListener('click', doReplay)
  forwardButton.addEventListener('click', doForward)

  const sliderAdapter = slider.foundation.adapter
  const oldEmitDragStartEvent = sliderAdapter.emitDragStartEvent
  const oldEmitDragEndEvent = sliderAdapter.emitDragEndEvent
  sliderAdapter.emitDragStartEvent = (value, thumb) => {
    if (!sliderDragging) {
      sliderDragging = true
      dragWasPlaying = !audioEl.paused
      if (dragWasPlaying) audioEl.pause()
    }
    oldEmitDragStartEvent(value, thumb)
  }
  sliderAdapter.emitDragEndEvent = (newValue, thumb) => {
    if (sliderDragging) {
      sliderDragging = false
      if (dragWasPlaying) audioEl.play()
    }
    oldEmitDragEndEvent(newValue, thumb)
  }

  sliderEl.addEventListener('MDCSlider:change', () => {
    const val = slider.getValue()
    if (Number.isFinite(val)) {
      audioEl.currentTime = val
    }
  })

  sliderEl.addEventListener('MDCSlider:input', () => {
    const val = slider.getValue()
    if (Number.isFinite(val)) {
      updateTimeText(val)
    }
  })

  const actionHandlers = [
    ['play', () => audioEl.play()],
    ['pause', () => audioEl.pause()],
    ['stop', closePlayer],
    ['seekbackward', details => {
      audioEl.currentTime -= details.seekOffset || 10
    }],
    ['seekforward', details => {
      audioEl.currentTime += details.seekOffset || 10
    }],
  ]

  if ('mediaSession' in navigator) {
    for (const [action, handler] of actionHandlers) {
      try {
        navigator.mediaSession.setActionHandler(action, handler)
      } catch (err) {}
    }
  }

  // TODO: implement seekto and setPositionState of media session API

  document.addEventListener('keydown', event => {
    if (!playerVisible) return
    if (event.target.tagName === 'INPUT' && event.target.type !== 'range') return
    if (event.key === ' ' && !event.repeat) {
      event.preventDefault()
      doPlayPause()
    }
    if (event.key === 'ArrowLeft') {
      event.preventDefault()
      doReplay()
    }
    if (event.key === 'ArrowRight') {
      event.preventDefault()
      doForward()
    }
  })

  return {
    play (unitTitle, moduleTitle, audioUrl) {
      audioEl.autoplay = true
      audioEl.src = audioUrl
      titleEl.textContent = unitTitle
      moduleEl.textContent = moduleTitle
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: unitTitle,
          album: moduleTitle
        })
      }
      updatePaused()
      updateDuration()
      updateTime()
      playerEl.classList.remove('hidden')
      playerVisible = true
      slider.layout()
    }
  }
}

function playAudio (unitTitle, moduleTitle, audioUrl) {
  if (!audioPlayer) audioPlayer = createAudioPlayer()
  audioPlayer.play(unitTitle, moduleTitle, audioUrl)
}
